<template>
<!--  <MainHeader headline='Trainerlizenz' text='Erfassen Sie Ihre Trainerlizenz'/>-->

  <div class="card p-1 p-lg-4">
    <div class="card-body">


  <template v-if="licenseList && licenseList.length > 0">

    <div style="margin-bottom: 49px;" v-for="l in licenseList">
      <div class="card p-1 p-lg-4">
        <div class="card-body">
          <h4 class="pb-3 text-center">{{ l._licenseTitle }}</h4>
          <p class="card-title text-center">Ihre Lizenz mit der Nummer <strong>{{l.license_no}}</strong>
            <template v-if="l.verified ==='verified'"> wurde akzeptiert. </template>
            <template v-else-if="l.verified === 'waiting'"> wurde eingereicht und wird geprüft.</template>
            <template v-else-if="l.verified === 'denied'"> wurde abgelehnt.</template>
          </p>
          <p class="card-text text-center" v-if="l.verified ==='verified'">Ihre Lizenz ist gültig bis <strong>{{$filters.formatDate(l.valid_to)}}.</strong></p>
          <p class="card-text commentText" v-if="l.verified === 'denied' && l.verified_comment && l.verified_comment !== ''" style="text-align: center;     font-style: italic;
    margin-top: 24px;" >
            {{l.verified_comment}}
          </p>
          <p v-if="l.verified ==='verified'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-check" style="color: green;"></i></p>
          <p v-if="l.verified ==='waiting'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-question" style="color: #e4c82c;"></i></p>

          <p v-if="l.verified ==='denied'"  style="text-align: center;" class="fa-4x"  ><i class="fa-regular fa-circle-exclamation" style="color: darkred;"></i></p>
        </div>
      </div>

    </div>

  </template>

      <h4 style="margin-bottom: 24px;">Erfassung neuer Lizenzen</h4>
      <p class="card-text" style="margin-bottom: 24px;">Hier können Sie Ihre Trainerlizenz erfassen. Bitte laden Sie die Vorder- und Rückseite Ihres Lizenzausweises hoch und geben Sie die Lizenznummer und Gültigkeitsdauer an.</p>

<div class="row">
  <div class="col-12 col-lg-6">


    <div class="form-floating  mb-3">

    <select v-model="licence.license" id="sellik" class="form-select">
      <option selected value="">Lizenz wählen</option>

      <option :value="lic.id" v-for="lic in licences">{{ lic.title }}</option>
    </select>
    <label for="sellic">Lizenz wählen</label>
  </div>
<!--    <div class="form-floating mb-3">-->
<!--      <input class="form-control" id="nextLic" type="date" v-model="licence.valid_from" placeholder="Lizenz gültig von">-->
<!--      <label for="nextLic">Lizenz gültig von</label>-->
<!--    </div>-->
    <div class="form-floating  mb-3">
      <input class="form-control" id="nextLiclk" type="date" v-model="licence.valid_to" placeholder="Lizenz gültig bis">
      <label for="nextLiclk">Lizenz gültig bis</label>
    </div>
    <div class="form-floating mb-3">
      <input class="form-control" type="date" v-model="licence.start_from" id="startFrom" placeholder="Start der Ausbildung">
      <label for="startFrom">Start der Ausbildung</label>
    </div>
    <div class="form-floating  mb-3">
      <input class="form-control" type="text" maxlength="8" v-model="licence.license_no" id="licNo" placeholder="Lizenznummer">
      <label for="licNo">Lizenznummer</label><span style="font-size: 12px; color: #899;">Maximal 8 Zeichen</span>
    </div></div>
  <div class="col-12 col-lg-6">
    <h5 style="margin-top: 18px;"><template v-if="licence.license_card_front"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Vorderseite Lizenz&shy;ausweis</h5>
    <UploadComponent :compact="true" v-model="licence.license_card_front"  v-if="licence.license_card_front === ''" />
    <template v-if="licence.license_card_front !== ''">
    <h5 style="margin-top: 48px;"><template v-if="licence.license_card_back"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Rückseite Lizenz&shy;ausweis</h5>
    <UploadComponentBack :compact="true" v-model="licence.license_card_back" v-if="licence.license_card_back === ''" /></template>
    <!-- show only if all values are filled -->
    <template v-if="licence.license_card_back !== '' && licence.license_card_front !=='' && licence.license_no !== '' && licence.valid_to !== '' && licence.license !== '' && licence.start_from !== ''">
      <template v-if="loading">
        <button type="button" class="btn btn-primary" style="margin-top: 39px;" disabled><i class="fa-regular fa-spinner fa-spin"></i> Speichern</button>
      </template>
      <template v-else>


      <button type="button" class="btn btn-primary" style="margin-top: 39px;" @click="submitToServer()">Speichern</button>
      </template>
    </template>
    <template v-else>

      <button type="button" class="btn btn-primary"  style="margin-top: 155px" disabled >Speichern nach Upload möglich</button>
    </template>
  </div>
</div>

    </div>
  </div>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import UploadComponent from "@/components/UploadComponentTrainer.vue";
import UploadComponentBack from "@/components/UploadComponentTrainerBack.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'TrainerlizenzView',
  components: {

    MainHeader, UploadComponent,UploadComponentBack
  },
  computed: {
    ...mapGetters(["getApiUrl"])
  },
  data() {
    return {
      licences: [],
      licenseList: [],
      licence: {
        license: '',
        valid_from: '',
        valid_to: '',
        start_from:'',
        license_no: '',
        license_card_front: '',
        license_card_back: '',
        verified: ''
      },
      loading: false
    }
  },
  methods: {
    submitToServer() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'trainer/license'  , this.licence, {
        headers
      })
        .then(response => {
          this.getLicense();
          this.licence = {
            license: '',
            valid_from: '',
            valid_to: '',
            start_from:'',
            license_no: '',
            license_card_front: '',
            license_card_back: '',
            verified: ''
          };

        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getLicense(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'trainer/license/list'  , {
        headers
      })
        .then(response => {
          console.log(response);
          this.licenseList = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getLicenses(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'config/trainerLicenses'  , {
        headers
      })
        .then(response => {
          this.licences = response.data.result.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.getLicenses();
    this.getLicense();
  }
}
</script>
