<template>

  <div v-if="$store.state.currentApplicationClosedState === false">
    <div class="buttons"  style="padding:24px 0px;">
    <button class="btn me-2 mb-2" :class="(infoTab === 'welcome') ? 'btn-primary' : 'btn-secondary'" @click="infoTab = 'welcome'">Anschreiben</button>
    <button class="btn me-2 mb-2" :class="(infoTab === 'info') ? 'btn-primary' : 'btn-secondary'" @click="infoTab = 'info'">Lehrgangsinformationen</button>
      <button class="btn btn-danger  mb-2" @click="deleteApplication()">Bewerbung abbrechen</button>
    </div>
  <div  class="editorWrapper"  v-if="$store.state.currentApplicationClosedState === false && infoTab === 'welcome'" v-html="welcomeText"></div>
  <div class="editorWrapper" v-if="$store.state.currentApplicationClosedState === false && infoTab === 'info'" v-html="infoText"></div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">

          <h4 v-if="$store.state.currentApplication.state === 'declined' || $store.state.currentApplication.state === 'declined_user' " class="">Bewerbung wurde nicht abgeschlossen</h4>
        <h4 v-else>Bewerbung abgeschlossen</h4>

        <template v-if="$store.state.currentApplication.state === 'declined' || $store.state.currentApplication.state === 'declined_user'">
          <p>Dies sind Ihre nicht eingereichten Bewerbungsunterlagen.</p>
        </template>
        <template v-else-if="$store.state.currentApplication.state === 'declined_02' || $store.state.currentApplication.state === 'declined_03' || $store.state.currentApplication.state === 'declined_05'">
          <p>Dies sind Ihre geprüften eingereichten Bewerbungsunterlagen.</p>
        </template>
        <template v-else-if="$store.state.currentApplication.state === 'declined_04'">
          <p> Dies sind Ihre ungeprüften eingereichten Bewerbungsunterlagen.</p>
        </template>
 <template v-else-if="$store.state.currentApplication.state === 'progress' || $store.state.currentApplication.state === 'checking' || $store.state.currentApplication.state === 'discus'">
          <p> Ihre Unterlagen werden aktuell von uns geprüft.</p>
        </template>

        </div>
      <p v-if="$store.state.currentApplication.state === 'accepted' || $store.state.currentApplication.state === 'prio_4' || $store.state.currentApplication.state === 'prio_3' || $store.state.currentApplication.state === 'prio_2' || $store.state.currentApplication.state === 'prio_1' ">
      <button style="width:400px;" class="btn btn-success" @click="downloadPDF()">Download Lebenslauf</button>
      </p>
      </div>

  </div>

</template>

<script>


import axios from "axios";

export default {
  props: {
    welcomeText: '',
    infoText: ''
  },
  data() {
    return {
      infoTab: 'welcome',
    }
  },
  name: 'ApplicationIntroduction',
  methods: {
    deleteApplication() {
      this.$confirm("Bitte bestätigen!", "Sie möchten sich nicht mehr bewerben und alle Eingaben löschen?", "question", {cancelButtonText: "Nein, doch nicht!", confirmButtonColor:"#21AE80"}).then(() => {
        localStorage.setItem("deletedApplication", "true");
        console.log(this.$store.state.currentApplication);
          const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.delete(this.$store.state.apiUrl + "plugins/dfb/deleteApplication/" + this.$store.state.currentApplication.id, {headers}).then((r) => {


        this.$router.push("/applications");
      });
      }).catch(() => {
        localStorage.setItem("deletedApplication", "false");
      });

    },
    downloadPDF() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestPDFExport/" + this.$store.state.currentApplication.id, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/pdf;base64,' + r.data.result.data.file;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'lebenslauf_' + r.data.result.data.name + '.pdf');
        window.document.body.appendChild(a);
        a.click();
      });
    }
  }
}

</script>
