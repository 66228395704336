<template>
<!--  <MainHeader headline='Trainerlizenz' text='Erfassen Sie Ihre Trainerlizenz'/>-->

  <div class="card p-1 p-lg-4">
    <div class="card-body">


  <template v-if="attestList && attestList.length > 0">

    <div style="margin-bottom: 49px;" v-for="l in attestList">
      <div class="card p-1 p-lg-4">
        <div class="card-body">
          <h4 class="pb-3 text-center">{{ l._attestTitle }}</h4>
          <p class="card-title text-center">Ihr Attest
            <template v-if="l.verified ==='verified'"> wurde akzeptiert. </template>
            <template v-else-if="l.verified === 'waiting'"> wurde eingereicht und wird geprüft.</template>
            <template v-else-if="l.verified === 'denied'"> wurde abgelehnt.</template>
          </p>
          <p class="card-text text-center" v-if="l.verified ==='verified'">Ihre Lizenz ist gültig bis <strong>{{$filters.formatDate(l.valid_to)}}.</strong></p>
          <p class="card-text commentText" v-if="l.verified === 'denied' && l.verified_comment && l.verified_comment !== ''" style="text-align: center;     font-style: italic;
    margin-top: 24px;" >
            {{l.verified_comment}}
          </p>
          <p v-if="l.verified ==='verified'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-check" style="color: green;"></i></p>
          <p v-if="l.verified ==='waiting'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-question" style="color: #e4c82c;"></i></p>

          <p v-if="l.verified ==='denied'"  style="text-align: center;" class="fa-4x"  ><i class="fa-regular fa-circle-exclamation" style="color: darkred;"></i></p>
        </div>
      </div>

    </div>

  </template>

      <h4 style="margin-bottom: 24px;">Erfassung neuer Atteste</h4>
      <p class="card-text" style="margin-bottom: 24px;">Hier können Sie Ihr Attest erfassen. Bitte laden Sie die Datei in PDF-Form hoch.</p>

<div class="row">
  <div class="col-12 col-lg-6">


    <div class="form-floating  mb-3">


    <label for="sellic">Attest wählen</label>
  </div>
    <div class="form-floating mb-3">
      <input class="form-control" id="nextLic" type="date" v-model="attest.valid_from" placeholder="Ausstellungsdatum">
      <label for="nextLic">Ausstellungsdatum</label>
    </div>


 </div>
  <div class="col-12 col-lg-6">
    <h5 style="margin-top: 18px;"><template v-if="attest.attest_pdf"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Attest Datei</h5>
    <UploadComponentAttest :compact="true" v-model="attest.attest_pdf"  v-if="attest.attest_pdf === ''" />

    <!-- show only if all values are filled -->
    <template v-if="attest.attest_pdf !== ''  && attest.valid_from !== '' ">
      <template v-if="loading">
        <button type="button" class="btn btn-primary" style="margin-top: 39px;" disabled><i class="fa-regular fa-spinner fa-spin"></i> Speichern</button>
      </template>
      <template v-else>


      <button type="button" class="btn btn-primary" style="margin-top: 39px;" @click="submitToServer()">Speichern</button>
      </template>
    </template>
    <template v-else>

      <button type="button" class="btn btn-primary"  style="margin-top: 155px" disabled >Speichern nach Upload möglich</button>
    </template>
  </div>
</div>

    </div>
  </div>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import UploadComponentAttest from "@/components/UploadComponentAttest.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'AttestView',
  components: {

    MainHeader, UploadComponentAttest
  },
  computed: {
    ...mapGetters(["getApiUrl"])
  },
  data() {
    return {
      attests: [],
      attestList: [],
      attest: {
        attest_type: 'doctor',
        valid_from: '',
        valid_to: '',
        attest_pdf: ''
      },
      loading: false
    }
  },
  methods: {
    submitToServer() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'trainer/attest'  , this.attest, {
        headers
      })
        .then(response => {
          this.getAttest();
          this.attest = {
            attest_type: 'doctor',
            valid_from: '',
            valid_to: '',
            attest_pdf: '',
            verified: ''
          };

        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAttest(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'trainer/attest/list/doctor'  , {
        headers
      })
        .then(response => {
          this.attestList = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

  },
  created() {

    this.getAttest();
  }
}
</script>
